import { MediaUploadFileInfo } from "@kaltura/mediaspace-shared-upload";
import FileRow, { FileRowProps } from "./fileRow/FileRow";
import BulkActions from "./bulk-actions/BulkActions";
import { useState } from "react";

export interface FileRowsProps
    extends Pick<
        FileRowProps,
        "editForm" | "onCancel" | "onSubmit" | "onRemove" | "bulkUpdatesCount" | "publishEnabled"
    > {
    files: MediaUploadFileInfo[];
    entryIds: string[];
    onCancelAll: () => void;
    onEditAll: () => void;
    onPublishAll: () => void;
}

const FileRows = ({
    files,
    entryIds,
    onCancelAll,
    onEditAll,
    onPublishAll,
    publishEnabled,
    ...rest
}: FileRowsProps) => {
    const [fileOpenForEdit, setFileOpenForEdit] = useState<string | undefined>(
        files.length === 1 ? files[0].fileMetaData.tokenId : undefined
    );
    const [fileWantsToOpenForEdit, setFileWantsToOpenForEdit] = useState<string>();

    return (
        <>
            <BulkActions
                files={files}
                entryIds={entryIds}
                onCancel={onCancelAll}
                onEdit={onEditAll}
                onPublish={onPublishAll}
                publishEnabled={publishEnabled}
            />
            {files
                .concat()
                .reverse()
                .map((file) => {
                    const tokenId = file.fileMetaData.tokenId;

                    return (
                        <FileRow
                            {...rest}
                            key={`file_${tokenId}`}
                            fileInfo={file}
                            isOpen={fileOpenForEdit === tokenId}
                            shouldClose={!!fileWantsToOpenForEdit}
                            onEdit={(tokenId: string) => {
                                if (!fileOpenForEdit) {
                                    setFileOpenForEdit(tokenId);
                                }
                                else {
                                    setFileWantsToOpenForEdit(tokenId);
                                }
                            }}
                            onClose={() => {
                                if (fileOpenForEdit === tokenId) {
                                    if (fileOpenForEdit === fileWantsToOpenForEdit) {
                                        // close all (specifically current) panels
                                        setFileOpenForEdit(undefined);
                                    }
                                    else {
                                        // open next panel
                                        setFileOpenForEdit(fileWantsToOpenForEdit);
                                    }
                                    // reset:
                                    setFileWantsToOpenForEdit(undefined);
                                }
                            }}
                            publishEnabled={publishEnabled}
                        />
                    );
                })}
        </>
    );
};

export default FileRows;
